import React = require("react");

export interface ModalCtxData {
  modalContent: JSX.Element;
  show: boolean;
  open: (o: { modalContent: JSX.Element }) => void;
  close: () => void;
}

export const ModalContext = React.createContext<ModalCtxData>({
  show: false,
  modalContent: <></>,
  open: () => {
    throw "missing context";
  },
  close: () => {
    throw "missing context";
  },
});
