import React = require("react");

export const storageTfaTokenKey = (projectId: string) =>
  `tfaToken-${projectId}`;
export const storageTfaExpiresAtKey = (projectId: string) =>
  `tfaExpiresAt-${projectId}`;

export type PortalType = "ec" | "tsdex" | "tsdin";

export function getProtalType(): PortalType {
  if ((window as any).portalType === "ec") {
    return "ec";
  }
  if ((window as any).portalType === "tsdin") {
    return "tsdin";
  }
  if ((window as any).portalType === "tsdex") {
    return "tsdex";
  }
  throw `error: window.portalType = "${(window as any).portalType}"`;
}

export enum AccessTokenType {
  survey_member,
  survey_export,
  survey_import,
  survey_admin,
}

export type AccessTokenTypeString = keyof typeof AccessTokenType;

type PortalSetting = {
  id: PortalType;
  name: string;
  sinkName: string;
  logo: any;
  accessTokenType: AccessTokenTypeString;
  welcomeMessage: JSX.Element;
};

const portalSettings: {
  [k in PortalType]: PortalSetting;
} = {
  ec: {
    id: "ec",
    name: "Educloud",
    sinkName: "Educloud",
    logo: require(`../../../ec/img/logo_56x56.png`),
    accessTokenType: "survey_member",
    welcomeMessage: (
      <p>
        This webservice shows the submissions of all Nettskjemas that are
        activated for your Educloud project.
      </p>
    ),
  },
  tsdex: {
    id: "tsdex",
    name: "TSD",
    sinkName: "TSD",
    logo: require(`../../../tsdex/img/logo_56x56.png`),
    accessTokenType: "survey_export",
    welcomeMessage: (
      <p>
        This webservice shows the submissions of all Nettskjemas that are
        activated for your TSD project.
      </p>
    ),
  },
  tsdin: {
    id: "tsdin",
    name: "TSD (internal)",
    sinkName: "TSD",
    logo: require(`../../../tsdin/img/logo_56x56.png`),
    accessTokenType: "survey_member",
    welcomeMessage: (
      <>
        <p>
          Data delivered from Nettskjema.no to your TSD project is available in
          the folder <code>\\durable\nettskjema-data\</code> and via this
          portal. We recommend you use Firefox in your project and log in with
          your TSD-user to access the data.
        </p>
        <p>
          Data delivered before February 1st, 2023 is available in the folder{" "}
          <code>\\durable\csvfiles\</code>.
        </p>
      </>
    ),
  },
};

export function getPortalSetting(): PortalSetting {
  return portalSettings[getProtalType()];
}

export const naSymbol = "NA";

export namespace paramsNames {
  export const proj = "proj";
}
