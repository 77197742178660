import * as React from "react";
import { Message, MessageDisplay, MessageType } from "./messagedisplay";

export const MessagesContext = React.createContext<Message[]>([]);

export const MessagesCtrlContext = React.createContext<{
  addErrorMessage: (content: JSX.Element | string) => void;
  addInfoMessage: (content: JSX.Element | string) => void;
  addSuccessMessage: (content: JSX.Element | string) => void;
  clearMessages: () => void;
}>({
  addErrorMessage: () => {},
  addSuccessMessage: () => {},
  addInfoMessage: () => {},
  clearMessages: () => {},
});

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [messages, setMessages] = React.useState<Message[]>([]);

  return (
    <MessagesCtrlContext.Provider
      value={(function () {
        /* cache messages to allow multiple setMessages without a ctx update inbetween */
        /* Note: is there a more streamlined approach to this? */
        let _messages = messages;
        const _addMessage = (
          type: MessageType,
          content: JSX.Element | string
        ) => {
          const m: Message = {
            content,
            type,
          };
          _messages = [..._messages, m];
          setMessages(_messages);
        };

        return {
          addErrorMessage: (content: JSX.Element | string) =>
            _addMessage(MessageType.error, content),
          addInfoMessage: (content: JSX.Element | string) =>
            _addMessage(MessageType.info, content),
          addSuccessMessage: (content: JSX.Element | string) =>
            _addMessage(MessageType.success, content),
          clearMessages: () => {
            _messages = [];
            setMessages(_messages);
          },
        };
      })()}
    >
      <MessagesContext.Provider value={messages}>
        {children}
      </MessagesContext.Provider>
    </MessagesCtrlContext.Provider>
  );
}
