import * as React from "react";
import { getPortalSetting } from "../helper/consts";
import { Routes } from "../helper/routes";
import { MessageDisplay } from "./messagedisplay";
import { ModalProvider } from "./modal";

export interface BreadcrumbEntry {
  title: string;
  url: string | undefined;
}

export function Scaffold({
  title,
  children,
  subtitle,
  actions,
  breadcrumbs,
}: {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  breadcrumbs?: (BreadcrumbEntry | null)[];
}) {
  return (
    <ModalProvider>
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid px-sm-5 px-2 py-sm-1">
          <a className="navbar-brand" href={Routes.Entry}>
            <img width={56} height={56} src={getPortalSetting().logo} />
            <strong className="ms-3 me-2">{`${
              getPortalSetting().name
            } Nettskjema`}</strong>
            {process.env.IS_PREVIEW === "1" && (
              <span className="badge rounded-pill bg-danger">Preview</span>
            )}
          </a>
          <ul className="navbar-nav ms-lg-auto">{actions}</ul>
        </div>
      </nav>
      <nav aria-label="breadcrumb" className="shadow-sm">
        {breadcrumbs && (
          <div className="container-fluid px-sm-5 px-2">
            <ol className="breadcrumb placeholder-glow py-1">
              {breadcrumbs.map((be, i) => {
                if (be === null) {
                  return (
                    <li className="breadcrumb-item w-25" key={i.toString()}>
                      <span className="placeholder w-75"></span>
                    </li>
                  );
                }
                if (be.url) {
                  return (
                    <li className="breadcrumb-item" key={be.title}>
                      <a className="" href={be.url}>
                        {be.title}
                      </a>
                    </li>
                  );
                }
                return (
                  <li className="breadcrumb-item active" key={be.title}>
                    {be.title}
                  </li>
                );
              })}
            </ol>
          </div>
        )}
      </nav>
      <div className="container-fluid">
        <div className="mx-sm-5 mx-2">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto h1 mt-1">{title}</div>
            {/* <div className="col-auto">{actions}</div> */}
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-auto h2 text-muted">{subtitle}</div>
          </div>
          <MessageDisplay />
          {children}
        </div>
      </div>
      <footer className="position-fixed bottom-0 end-0 px-1 bg-white bg-opacity-75">
        <div className="col-auto text-muted" style={{ fontSize: "0.7em" }}>
          {process.env.VERSION || ""}
        </div>
      </footer>
    </ModalProvider>
  );
}
