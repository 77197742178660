import React = require("react");
import { ModalContext, ModalCtxData } from "./modalctx";

// function Modal({
//   body,
//   buttons,
//   title,
// }: {
//   body: JSX.Element;
//   buttons: JSX.Element[];
//   title: string;
// }) {
//   const { close } = React.useContext(ModalContext);
//   return (
//     <>
//       <div className="modal-header">
//         <h5 className="modal-title">{title}</h5>
//         <button type="button" className="btn-close" onClick={close}></button>
//       </div>
//       <div className="modal-body">{body}</div>
//       <div className="modal-footer">
//         {buttons.map((e, i) => (
//           <React.Fragment key={i}>{e}</React.Fragment>
//         ))}
//       </div>
//     </>
//   );
// }

export class ModalProvider extends React.Component<{
  children: React.ReactNode;
}> {
  state: ModalCtxData;

  constructor(
    props:
      | { children: JSX.Element }
      | Readonly<{
          children: JSX.Element;
        }>
  ) {
    super(props);

    this.state = {
      open: ({ modalContent }: { modalContent: JSX.Element }) => {
        this.setState((state) => ({
          ...this.state,
          show: true,
          modalContent,
        }));
        document.body.classList.add("modal-open");
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = "0px";
      },
      close: () => {
        this.setState((state) => ({
          ...this.state,
          show: false,
        }));
        document.body.classList.remove("modal-open");
        document.body.style.cssText = "";
      },
      show: false,
      modalContent: <></>,
    };
  }

  render(): React.ReactNode {
    return (
      <ModalContext.Provider value={this.state}>
        <div
          className={`modal fade ${this.state.show ? "show d-block" : ""}`}
          tabIndex={-1}
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div className="modal-content">{this.state.modalContent}</div>
          </div>
        </div>
        {this.props.children}
        {this.state.show && <div className="modal-backdrop fade show" />}
      </ModalContext.Provider>
    );
  }
}
