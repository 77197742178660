import React = require("react");
import { ModalContext } from "./modalctx";

export function ModalCancelButton() {
  const { close } = React.useContext(ModalContext);
  return (
    <button type="button" className="btn btn-secondary" onClick={close}>
      Cancel
    </button>
  );
}
