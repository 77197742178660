import * as React from "react";
import { MessagesContext } from "./appproviders";

export enum MessageType {
  error,
  info,
  success,
}

export interface Message {
  type: MessageType;
  content: JSX.Element | string;
}

export function MessageDisplay() {
  const messages = React.useContext(MessagesContext);

  const messageBsClasses: Record<string, string> = {
    [MessageType.error]: "danger",
    [MessageType.info]: "info",
    [MessageType.success]: "success",
  };
  return (
    <div className="">
      {messages.reverse().map((m, i) => (
        <div key={i.toString()} className="row align-items-center">
          <div
            className={`offset-md-2 col-md-8 offset-lg-3 col-lg-6 alert alert-${
              messageBsClasses[m.type]
            } alert-dismissible`}
            role="alert"
          >
            {m.content}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      ))}
    </div>
  );
}
