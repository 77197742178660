export namespace Routes {
  export const StartTsd = "./tsd/index.html";
  export const StartEd = "./ec/index.html";

  export const Entry = "./index.html";
  export const Home = "./home.html";
  const _Login = "./login.html";
  const _Forms = "./forms.html";
  const _Form = "./form.html";
  const _Submission = "./submission.html";
  const _Audits = "./audits.html";
  const _Audit = "./audit.html";

  export const paramFormId = "form";
  export const paramProjectId = "proj";
  export const paramAuditId = "audit";
  export const paramSubmissionId = "submission";
  export const paramEdit = "edit";

  export const Login = (pid: string) =>
    `${_Login}?${paramProjectId}=${encodeURIComponent(pid)}`;

  export const Forms = (pid?: string) =>
    pid ? `${_Forms}?${paramProjectId}=${encodeURIComponent(pid)}` : _Forms;

  export const Form = (pid: string, formId: string) =>
    `${_Form}?${paramProjectId}=${encodeURIComponent(
      pid
    )}&${paramFormId}=${encodeURIComponent(formId)}`;

  export const Submission = (
    pid: string,
    formId: string,
    submissionId: string,
    edit?: boolean
  ) =>
    [
      _Submission,
      `?${paramProjectId}=${encodeURIComponent(pid)}`,
      `&${paramFormId}=${encodeURIComponent(formId)}`,
      `&${paramSubmissionId}=${encodeURIComponent(submissionId)}`,
      ...(edit !== undefined ? [`&${paramEdit}=${edit ? 1 : 0}`] : []), // cond. array element
    ].join("");

  export const Audits = (pid: string, formId: string) =>
    `${_Audits}?${paramProjectId}=${encodeURIComponent(
      pid
    )}&${paramFormId}=${encodeURIComponent(formId)}`;

  export const Audit = ({
    projectId,
    formId,
    auditId,
  }: {
    projectId: string;
    formId: string;
    auditId: number;
  }) =>
    `${_Audit}?${paramProjectId}=${encodeURIComponent(
      projectId
    )}&${paramFormId}=${encodeURIComponent(formId)}&${paramAuditId}=${auditId}`;
}
